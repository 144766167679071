import React from "react";

const VodacomLogo = ({ style }) => (
<svg width={style.width} height={style.height} viewBox="0 0 490 57" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M481.11 37.37L489.67 0.290009H479L470.44 37.37H481.11ZM477.45 54C478.838 52.7509 479.773 51.077 480.11 49.24C480.277 48.4187 480.274 47.572 480.102 46.7517C479.93 45.9315 479.593 45.155 479.11 44.47C478.619 43.7928 477.967 43.2494 477.212 42.8895C476.458 42.5297 475.625 42.3648 474.79 42.41C472.874 42.39 471.029 43.1309 469.66 44.47C468.276 45.7251 467.344 47.4017 467.01 49.24C466.84 50.0595 466.841 50.9053 467.013 51.7244C467.185 52.5434 467.524 53.3181 468.01 54C468.506 54.6909 469.171 55.2437 469.941 55.6059C470.711 55.9682 471.561 56.1279 472.41 56.07C474.285 56.0682 476.082 55.3239 477.41 54H477.45ZM434 55.16L440.83 28.68L447.83 55.16H456.26L469.59 14.51H458.74L451.74 40.23L445.08 14.51H436.64L430.06 40.23L423.06 14.51H412.18L425.51 55.16H434ZM368.38 55.16V31.16C368.435 28.4261 368.081 25.6993 367.33 23.07C366.749 21.0432 365.724 19.1711 364.33 17.59C363.062 16.1946 361.468 15.1362 359.69 14.51C357.797 13.8351 355.8 13.4967 353.79 13.51C351.676 13.5139 349.578 13.8826 347.59 14.6C345.58 15.3069 343.712 16.3631 342.07 17.72L341.56 14.52H332.03V55.17H342.49V26.32C343.57 25.2536 344.772 24.3172 346.07 23.53C347.426 22.6965 348.988 22.26 350.58 22.27C351.618 22.2217 352.652 22.4268 353.592 22.8676C354.533 23.3084 355.353 23.9717 355.98 24.8C357.28 26.49 357.92 28.88 357.92 31.97V55.16H368.38Z" fill="#E30A18"/>
<path d="M412.84 34.86C412.84 38.875 411.649 42.7998 409.419 46.1381C407.188 49.4764 404.018 52.0783 400.308 53.6148C396.599 55.1512 392.517 55.5532 388.579 54.7699C384.642 53.9867 381.024 52.0533 378.185 49.2143C375.346 46.3753 373.413 42.7581 372.63 38.8203C371.847 34.8825 372.249 30.8009 373.785 27.0915C375.321 23.3822 377.923 20.2118 381.262 17.9812C384.6 15.7506 388.525 14.56 392.54 14.56C397.924 14.56 403.087 16.6987 406.894 20.5057C410.701 24.3127 412.84 29.4761 412.84 34.86Z" fill="#E30A18"/>
<path d="M392.7 46.18C387.12 46.18 381.32 41.44 381.29 33.8C381.352 31.3465 381.937 28.9345 383.007 26.7254C384.076 24.5163 385.604 22.5609 387.49 20.99C390.958 18.1293 395.277 16.4977 399.77 16.35C400.262 16.3344 400.754 16.3917 401.23 16.52C399.376 16.9274 397.713 17.9489 396.512 19.4189C395.31 20.889 394.64 22.7216 394.61 24.62C394.602 24.7298 394.602 24.8402 394.61 24.95C400.79 26.46 403.61 30.19 403.61 35.35C403.607 36.7808 403.322 38.1969 402.77 39.5169C402.218 40.8368 401.41 42.0346 400.393 43.0412C399.376 44.0477 398.171 44.8433 396.845 45.382C395.52 45.9207 394.101 46.1919 392.67 46.18" fill="white"/>
<path d="M31.9601 15.36L21.8801 41.8L11.4701 15.36H0.560059L17.2501 55.18H26.1701L42.3701 15.36H31.9601Z" fill="#E30A18"/>
<path d="M71.3601 35.07C71.3682 33.4757 71.1767 31.8867 70.7901 30.34C70.4519 28.9605 69.8621 27.6553 69.0501 26.49C68.3117 25.4389 67.3527 24.5618 66.2401 23.92C65.044 23.2894 63.7122 22.9599 62.3601 22.9599C61.0079 22.9599 59.6762 23.2894 58.4801 23.92C57.3653 24.5589 56.4057 25.4366 55.6701 26.49C54.8581 27.6553 54.2682 28.9605 53.9301 30.34C53.5399 31.8863 53.3451 33.4753 53.3501 35.07C53.3402 36.6749 53.5351 38.2745 53.9301 39.83C54.2753 41.2032 54.8501 42.5083 55.6301 43.69C56.3487 44.7687 57.3114 45.6628 58.4401 46.3C59.642 46.9518 60.9931 47.2792 62.3601 47.25C63.7146 47.2816 65.0534 46.9538 66.2401 46.3C67.3566 45.6464 68.3159 44.7554 69.0501 43.69C69.8512 42.5149 70.4401 41.2085 70.7901 39.83C71.1816 38.274 71.3731 36.6745 71.3601 35.07V35.07ZM81.4401 35.07C81.4375 37.6626 81.0362 40.2394 80.2501 42.71C79.4834 45.1515 78.2769 47.4323 76.6901 49.44C75.0835 51.4512 73.056 53.0861 70.7501 54.23C68.1156 55.4293 65.2547 56.0499 62.3601 56.0499C59.4655 56.0499 56.6045 55.4293 53.9701 54.23C51.6641 53.0861 49.6367 51.4512 48.0301 49.44C46.4433 47.4323 45.2368 45.1515 44.4701 42.71C43.6796 40.2405 43.2781 37.663 43.2801 35.07C43.2747 32.4899 43.6764 29.925 44.4701 27.47C45.2371 25.053 46.444 22.7986 48.0301 20.82C49.6375 18.8302 51.6662 17.2216 53.9701 16.11C56.5904 14.8726 59.4631 14.2631 62.3601 14.33C65.2413 14.2598 68.0986 14.8696 70.7001 16.11C73.0166 17.2285 75.0635 18.8353 76.7001 20.82C78.3096 22.7892 79.5312 25.0457 80.3001 27.47C81.089 29.9262 81.4905 32.4903 81.4901 35.07" fill="#E30A18"/>
<path d="M112.18 24.12C110.431 23.3951 108.563 23.0018 106.67 22.96C105.104 22.9209 103.553 23.2641 102.15 23.96C100.914 24.6006 99.8456 25.5219 99.03 26.65C98.1989 27.8079 97.5731 29.1 97.18 30.47C96.7671 31.8831 96.5584 33.3479 96.56 34.82C96.5538 36.4223 96.7351 38.0198 97.1 39.58C97.4044 41.0003 97.9598 42.3548 98.74 43.58C99.4525 44.6913 100.406 45.6278 101.53 46.32C102.744 47.0205 104.129 47.3669 105.53 47.32C106.879 47.3073 108.204 46.964 109.39 46.32C110.535 45.7832 111.506 44.9339 112.19 43.87L112.18 24.12ZM113.01 55.18L112.59 52.13C111.531 53.2673 110.274 54.2025 108.88 54.89C107.194 55.7244 105.331 56.1362 103.45 56.09C100.925 56.1422 98.4292 55.5466 96.2 54.36C94.1294 53.2156 92.3315 51.636 90.93 49.73C89.4572 47.7111 88.3567 45.4456 87.68 43.04C86.9339 40.4631 86.5601 37.7927 86.57 35.11C86.5667 32.5331 86.9544 29.9706 87.72 27.51C88.4535 25.0911 89.6414 22.8341 91.22 20.86C92.8131 18.876 94.8242 17.2678 97.11 16.15C99.7012 14.9107 102.548 14.3008 105.42 14.37C107.719 14.4033 109.999 14.7912 112.18 15.52V0.0800171H122.42V55.18H113.01Z" fill="#E30A18"/>
<path d="M151.34 36.51C150.23 36.57 149.09 36.66 147.93 36.8C146.77 36.94 145.65 37.12 144.6 37.34C142.38 37.78 140.84 38.5367 139.98 39.61C139.155 40.5935 138.701 41.8361 138.7 43.12C138.678 43.7262 138.798 44.3291 139.05 44.8811C139.302 45.433 139.678 45.9188 140.15 46.3C141.341 47.1698 142.799 47.5945 144.27 47.5C145.601 47.4985 146.916 47.2157 148.13 46.67C149.307 46.1657 150.391 45.4695 151.34 44.61V36.51ZM152.16 55.14L151.75 52.14C150.574 53.4053 149.128 54.3896 147.52 55.02C145.722 55.714 143.807 56.0536 141.88 56.02C140.198 56.0256 138.528 55.7309 136.95 55.15C135.408 54.5986 133.985 53.7568 132.76 52.67C131.453 51.5065 130.413 50.0736 129.712 48.47C129.011 46.8663 128.666 45.1298 128.7 43.38C128.66 41.5623 129.058 39.7617 129.86 38.13C130.617 36.6355 131.692 35.3248 133.01 34.29C134.395 33.2157 135.932 32.353 137.57 31.73C139.314 31.0627 141.12 30.5671 142.96 30.25C144.396 29.9532 145.849 29.7461 147.31 29.63C148.89 29.49 150.2 29.37 151.25 29.25V28.68C151.25 26.4734 150.683 24.9034 149.55 23.97C148.202 22.961 146.541 22.4615 144.86 22.56C143.74 22.5547 142.624 22.6959 141.54 22.98C140.565 23.2155 139.617 23.5506 138.71 23.98C137.952 24.3484 137.218 24.7625 136.51 25.22C135.976 25.5561 135.465 25.9269 134.98 26.33L131.59 19.15C132.217 18.6263 132.886 18.1548 133.59 17.74C134.648 17.115 135.755 16.5766 136.9 16.13C138.321 15.5702 139.786 15.1287 141.28 14.81C143.002 14.4395 144.759 14.2584 146.52 14.27C148.553 14.2485 150.577 14.542 152.52 15.14C154.275 15.6791 155.886 16.6091 157.23 17.86C158.605 19.1768 159.664 20.7868 160.33 22.57C161.127 24.7775 161.507 27.1138 161.45 29.46V55.04L152.16 55.14Z" fill="#E30A18"/>
<path d="M201.32 51.79C200.93 52.07 200.32 52.46 199.5 52.95C198.512 53.524 197.474 54.0092 196.4 54.4C194.989 54.9151 193.537 55.3131 192.06 55.59C190.208 55.9455 188.326 56.113 186.44 56.09C184.051 56.1011 181.68 55.681 179.44 54.85C177.207 54.0322 175.179 52.7352 173.5 51.05C171.693 49.2138 170.29 47.0203 169.38 44.61C168.293 41.678 167.77 38.5665 167.84 35.44C167.778 32.2881 168.286 29.151 169.34 26.18C170.208 23.7295 171.569 21.4832 173.34 19.58C174.98 17.8541 176.981 16.5121 179.2 15.65C181.418 14.7963 183.774 14.3623 186.15 14.37C188.21 14.33 190.262 14.6377 192.22 15.28C193.82 15.8204 195.335 16.5847 196.72 17.55C197.907 18.3776 198.954 19.3905 199.82 20.55C200.556 21.5391 201.205 22.5896 201.76 23.69L194.16 28.81C193.305 27.3477 192.263 26.0031 191.06 24.81C190.424 24.1872 189.668 23.7009 188.837 23.381C188.007 23.0612 187.12 22.9145 186.23 22.95C185.112 22.9655 184.012 23.2388 183.016 23.7487C182.021 24.2586 181.156 24.9914 180.49 25.89C178.864 27.8367 178.05 30.8767 178.05 35.01C178.05 39.01 178.877 42.04 180.53 44.1C181.393 45.1568 182.495 45.9928 183.746 46.5388C184.996 47.0849 186.359 47.3252 187.72 47.24C189.067 47.2558 190.411 47.1046 191.72 46.79C192.785 46.5283 193.823 46.1667 194.82 45.71C195.593 45.3615 196.327 44.9324 197.01 44.43C197.457 44.098 197.884 43.7407 198.29 43.36L201.32 51.79Z" fill="#E30A18"/>
<path d="M233 35.07C233.005 33.4753 232.81 31.8863 232.42 30.34C232.082 28.9605 231.492 27.6553 230.68 26.49C229.944 25.4366 228.985 24.5589 227.87 23.92C226.674 23.2894 225.342 22.9599 223.99 22.9599C222.638 22.9599 221.306 23.2894 220.11 23.92C218.997 24.5618 218.038 25.4389 217.3 26.49C216.487 27.6536 215.9 28.9596 215.57 30.34C215.175 31.8853 214.98 33.475 214.99 35.07C214.975 36.6752 215.17 38.2754 215.57 39.83C215.912 41.2027 216.483 42.5077 217.26 43.69C217.959 44.7606 218.897 45.6541 220 46.3C221.202 46.9505 222.553 47.2779 223.92 47.25C225.275 47.2816 226.613 46.9538 227.8 46.3C228.919 45.649 229.878 44.7575 230.61 43.69C231.411 42.5149 232 41.2085 232.35 39.83C232.745 38.2745 232.94 36.6749 232.93 35.07H233ZM243.01 35.07C243.009 37.6635 242.604 40.241 241.81 42.71C241.046 45.1508 239.843 47.4316 238.26 49.44C236.636 51.4574 234.587 53.0927 232.26 54.23C229.627 55.4291 226.768 56.0496 223.875 56.0496C220.982 56.0496 218.123 55.4291 215.49 54.23C213.179 53.089 211.148 51.4537 209.54 49.44C207.952 47.4344 206.749 45.1528 205.99 42.71C205.196 40.241 204.791 37.6635 204.79 35.07C204.788 32.4894 205.193 29.9245 205.99 27.47C206.749 25.0518 207.953 22.7965 209.54 20.82C211.149 18.8276 213.181 17.2186 215.49 16.11C218.107 14.8727 220.976 14.2632 223.87 14.33C226.754 14.2597 229.615 14.8695 232.22 16.11C234.516 17.2347 236.542 18.8413 238.16 20.82C239.77 22.7892 240.991 25.0457 241.76 27.47C242.557 29.9245 242.962 32.4894 242.96 35.07" fill="#E30A18"/>
<path d="M298.47 55.18V31.8C298.568 29.5706 298.053 27.3569 296.98 25.4C295.98 23.78 294.15 22.96 291.45 22.96C290.649 22.9664 289.853 23.0945 289.09 23.34C288.314 23.5737 287.556 23.8611 286.82 24.2C286.163 24.4859 285.528 24.8201 284.92 25.2C284.458 25.503 284.017 25.8371 283.6 26.2C283.71 26.86 283.82 27.6 283.93 28.43C284.043 29.3322 284.097 30.2408 284.09 31.15V55.15H273.85V31.8C273.853 30.6504 273.756 29.5027 273.56 28.37C273.407 27.3818 273.068 26.4316 272.56 25.57C272.082 24.7848 271.415 24.1316 270.62 23.67C269.701 23.1712 268.665 22.9261 267.62 22.96C266.074 22.9649 264.559 23.3938 263.24 24.2C261.981 24.9414 260.816 25.8311 259.77 26.85V55.18H249.48V15.36H258.9L259.31 18.36C260.648 17.1803 262.172 16.2308 263.82 15.55C265.528 14.7962 267.373 14.4014 269.24 14.39C271.137 14.3928 273.022 14.6862 274.83 15.26C276.749 15.8762 278.513 16.8995 280 18.26C281.894 17.1258 283.906 16.2003 286 15.5C288.255 14.739 290.621 14.3605 293 14.38C295.546 14.311 298.084 14.704 300.49 15.54C302.358 16.2102 304.024 17.3461 305.33 18.84C306.591 20.3816 307.484 22.1909 307.94 24.13C308.49 26.4863 308.752 28.9006 308.72 31.32V55.19L298.47 55.18Z" fill="#E30A18"/>
</svg>
);

export default VodacomLogo;